import React from "react";
import {Templates} from '../components/templates/index'

const PianoRedirect = () => {
    return (
        <>
            <Templates/>
        </>
    );
};

export default PianoRedirect;