/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../components/sidebar";
import Navbar from "../../components/navbar/";
import Footer from "../../components/footer";
import ScrollToTop from "../../components/ScrollToTop";
import CardView from "../../components/cardView";
import { GlobalContext } from "../../contexts/GlobalContext";
import { CardViewContainer, CardViewTopH1, CardViewTopH2, CardViewWrapper } from "../cardView/cardViewElements";
import styled from "styled-components";
import Typical from "react-typical";
import { useLocation } from "react-router-dom";
import { LocationContainer, LocationText, LocationWrapper } from "../location/locationElements";

export const LoadingContainer = styled.div`
    width: 100%;
    text-align: center;
    font-size: 28px;
    color: var(--darkRed);
    background: palegoldenrod;
    padding-top: 20px;
    padding-bottom: 20px;
`

export const Templates = () => {
    const { fetchWebTemplatesCategories } = useContext(GlobalContext);

    let categoriesData = []
    const location = useLocation();

    const [categories, setCategories] = useState([])
    const [rowsCount, setRowsCount] = useState(0)
    const [items, setItems] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    let pageId = 0
    let pathName = '';
    const toggle = () => {
        setIsOpen(!isOpen)
    }
    const fetchCategories = async () => {
        try {

            if (location.pathname === '/guitars') {
                pageId = 1
                pathName = 'guitars';
            } else if (location.pathname === '/pianos') {
                pageId = 2
                pathName = 'pianos';
            } else if (location.pathname === '/bowStringed') {
                pageId = 3
                pathName = 'bowStringed';
            }

            const data = await fetchWebTemplatesCategories(pageId)

            if (data['success'] !== 0) {
                setItems(data.length);
                if (rowsCount === 0)
                    setRowsCount(data['countRows'])
                for (let i = 0; i < data.length; i++) {
                    categoriesData.push(

                        <CardView key={data[i]['data']['id']}
                            cardsData={data[i]['data']}
                            pathName={pathName}
                            cardsId={data[i]['data']['instruments_id']}
                            rowsCount={rowsCount}
                            cardViewIcon={data[i]['data']['type_img']}
                            CardViewH1URL={pageId}
                            CardViewH1Value={data[i]['data']['name']}
                            CardViewH2URL={'#'}
                            CardViewH2Value={pageId}
                            CardViewDescription={data[i]['data']['description']}
                            iscategory={"true"}
                            isCart={'true'}
                            items={data.length} />
                    )
                }
                setLoading(false)
                setCategories(categoriesData)
            }
        } catch (e) {
            if (e) {
                console.log(e.message)
            }
        }
    }

    useEffect(() => {
        fetchCategories()
    }, [])

    return (
        <>
            <ScrollToTop />
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <LocationContainer>
                <LocationWrapper>
                    <LocationText to="/">
                        Home
                    </LocationText>
                </LocationWrapper>
            </LocationContainer>
            {loading ?
                <LoadingContainer>
                    <Typical
                        steps={['Please wait...', 1000, 'We are getting your cart...', 500]}
                        loop={Infinity}
                        wrapper="p"
                    />
                </LoadingContainer>
                :
                <>
                    <CardViewContainer id={"cardView"}>
                        <CardViewTopH1 iscategory={"false"} to={'#'}>Calypso</CardViewTopH1>
                        <CardViewTopH2 iscategory={"false"} to={'#'}>The reason to play!</CardViewTopH2>
                        <CardViewWrapper iscategory={"false"} items={items}>
                            {categories}
                        </CardViewWrapper>
                    </CardViewContainer>

                </>
            }
            <Footer />
        </>
    );
};