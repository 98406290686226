import React, {createContext, Component} from "react";
import axios from 'axios'

export const GlobalContext = createContext();

export let baseURL = `https://calypsomusic.ca/`;
// export let baseURL = `http://localhost/`;


// Define the base URL
const Axios = axios.create({
    baseURL: baseURL+'calypsoAPI/',
});

class GlobalProvider extends Component {
   
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
    }

    // Root State
    state = {
    }


    // Fetch WebTemplates Categories
    fetchWebTemplatesCategories = async (pageId) => {
        let categories;
        if(pageId != null) {
            categories = await Axios.post('getCategories.php', {
                pageId: pageId
            });
        } else {
            categories = await Axios.get('getCategories.php')
        }

        return categories.data;
    }

    // Fetch WebTemplates Categories
    getInfoSectionPage = async () => {
        const infoSection = await Axios.get('getInfoSection.php');

        return infoSection.data;
    }

    getInfoImages = async () => {
        const infoImages = await Axios.get('getInfoImages.php');

        return infoImages.data;
    }

    getSingleProduct = async (productId, acc) => {
        if(acc != null) {
            const singleProduct = await Axios.post('getSingleAcc.php', {
                pageId: productId
            });
            return singleProduct.data;
        } else {
        const singleProduct = await Axios.post('getSingleProduct.php', {
            pageId: productId
        });
        return singleProduct.data;
        }
        
    }

    // Get All Templates Data
    getAllTemplatesData = async (categoryId, categoryName, typeId) => {

        if (categoryName === "Accessories") {
            const allTemplates = await Axios.post('getAccessories.php', {
                pageId: categoryId,
            });
            return allTemplates.data;
        } else {
            const allTemplates = await Axios.post('getItems.php', {
                pageId: typeId,
            });
            return allTemplates.data;
        }
    }

    render() {
        const contextValue = {
            rootState: this.state,
            getAllTemplatesData: this.getAllTemplatesData,
            fetchWebTemplatesCategories: this.fetchWebTemplatesCategories,
            getInfoSectionPage: this.getInfoSectionPage,
            getSingleProduct: this.getSingleProduct,
            getInfoImages: this.getInfoImages
        }
        return (
            <GlobalContext.Provider value={contextValue}>
                {this.props.children}
            </GlobalContext.Provider>
        )
    }
}

export default GlobalProvider;