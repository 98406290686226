/* eslint-disable array-callback-return */
import React, { useState, useEffect, useContext } from "react";
import { FaBars } from 'react-icons/fa';
import { IconContext } from "react-icons/lib";
import { SocialIcon } from 'react-social-icons'
import { animateScroll as scroll } from 'react-scroll';
import logo from "../../images/LogoGold.png"
import {
    Nav,
    NavbarContainer,
    NavLogo,
    MobileIcon,
    NavMenu,
    NavItem,
    NavLinksRoute,
    ImgLogo,
    TopNavContainer,
    TopNavMenu,
    TopNavIcons,
    NavDropDownContent,
    NavAContent,

} from "./navbarElements";
import { GlobalContext } from "../../contexts/GlobalContext";
import { PaginationContext } from "../pagination/paginationContext";

const Navbar = ({ toggle }) => {
    const { updatePageName } = useContext(PaginationContext);
    const [guitar, setGuitar] = useState([]);
    const [piano, setPiano] = useState([]);
    const [bow, setBow] = useState([]);


    const { fetchWebTemplatesCategories } = useContext(GlobalContext);




    useEffect(() => {
        let guitarArr = [];
        let pianoArr = [];
        let bowArr = [];
        const fetchCategories = async () => {
            try {
                const data = await fetchWebTemplatesCategories();
                if (data['success'] !== 0) {
                    data.map(x => {
                        if (x.data.instrument_id === "1") {
                            guitarArr.push(x.data);
                        } else if (x.data.instrument_id === "2") {
                            pianoArr.push(x.data);
                        } else if (x.data.instrument_id === "3") {
                            bowArr.push(x.data)
                        }
                    })
                    setGuitar(guitarArr);
                    setPiano(pianoArr);
                    setBow(bowArr)
                }
            } catch (e) {
                if (e) {
                    console.log(e.message)
                }
            }
        }
        return fetchCategories();

    }, [fetchWebTemplatesCategories])

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    // const updateData = (id, name, itemId, path) => {
    //     updatePath(path)
    //     updatePageName(id, name, itemId, path)
    // }
    return (
        <>
            <TopNavContainer>
                <TopNavMenu>
                    <TopNavIcons>
                        <SocialIcon url={"https://www.facebook.com/Calypso-Music-Instruments-109297437441222/"} network={"facebook"} style={{ height: 20, width: 20 }} />
                    </TopNavIcons>
                    <TopNavIcons>
                        <SocialIcon url={"https://www.instagram.com/calypsomusicinstrumemts/"} network={"instagram"} style={{ height: 20, width: 20 }} />
                    </TopNavIcons>
                    <TopNavIcons>
                        <SocialIcon url={"https://www.youtube.com/channel/UCRU9WtMHMjMju5c3wIdM5wg"} network={"youtube"} style={{ height: 20, width: 20 }} />
                    </TopNavIcons>
                    <TopNavIcons>
                        <SocialIcon url={"mailto:calypsomusicinstruments@gmail.com"} network={"email"} style={{ height: 20, width: 20 }} />
                    </TopNavIcons>
                </TopNavMenu>
            </TopNavContainer>
            <IconContext.Provider value={{ color: '#fff' }}>
                <Nav>
                    <NavbarContainer>
                        <NavLogo to={"/"} onClick={toggleHome}>
                            <ImgLogo src={logo} alt={logo} />
                        </NavLogo>
                        <MobileIcon onClick={toggle}>
                            <FaBars />
                        </MobileIcon>
                        <NavMenu>
                            <NavItem>
                                <NavLinksRoute to={"guitars"}
                                    smooth={"true"}
                                    duration={500}
                                    spy="true"
                                    exact={"true"}
                                    offset={-80}
                                    // onClick={() => updatePath('guitars')}
                                >Guitars

                                <NavDropDownContent>
                                        {guitar.map((item, index) => {
                                            return (
                                                <NavAContent key={index} to={'/categoryName'} onClick={() => updatePageName({categoryId: "#", categoryName: item.name, typeId: item.id, pageName: 'guitars'})}>
                                                    {item.name}
                                                </NavAContent>
                                            )
                                        })}
                                    </NavDropDownContent>
                                </NavLinksRoute>
                            </NavItem>
                            <NavItem>
                                <NavLinksRoute to={"pianos"}
                                    smooth={"true"}
                                    duration={500}
                                    spy="true"
                                    exact={"true"}
                                    offset={-80}
                                    // onClick={() => updatePath('pianos')}
                                >Pianos
                                <NavDropDownContent>
                                        {piano.map((item, index) => {
                                            return (
                                                <NavAContent key={index} to={'/categoryName'} onClick={() => updatePageName({categoryId: "#", categoryName: item.name, typeId: item.id, pageName: 'pianos'})}>
                                                    {item.name}
                                                </NavAContent>
                                            )
                                        })}
                                    </NavDropDownContent>
                                </NavLinksRoute>
                            </NavItem>
                            <NavItem>
                                <NavLinksRoute to={"bowStringed"}
                                    smooth={"true"}
                                    duration={500}
                                    spy="true"
                                    exact={"true"}
                                    offset={-80}
                                    // onClick={() => updatePath('bowStringed')}
                                >Bow Stringed Instruments
                                <NavDropDownContent>
                                        {bow.map((item, index) => {
                                            return (
                                                <NavAContent key={index} to={'/categoryName'} onClick={() => updatePageName({categoryId: "#", categoryName: item.name, typeId: item.id, pageName: 'bowStringed'})}>
                                                    {item.name}
                                                </NavAContent>
                                            )
                                        })}
                                    </NavDropDownContent>
                                </NavLinksRoute>
                            </NavItem>
                        </NavMenu>
                    </NavbarContainer>
                </Nav>
            </IconContext.Provider>
        </>
    );
};

export default Navbar;