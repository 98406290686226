/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import InfoSection from "../components/InfoSection";

import Footer from "../components/footer";
import ScrollToTop from "../components/ScrollToTop";
import { baseURL, GlobalContext } from "../contexts/GlobalContext";
import Typical from "react-typical";
import { LoadingContainer } from "../components/templates";

const Home = () => {
    const { getInfoSectionPage, getInfoImages } = useContext(GlobalContext);

    const [isOpen, setIsOpen] = useState(false);
    const [infoSection, setinfoSection] = useState([])
    const [loading, setLoading] = useState(true);

    let infoSectionData = []

    const toggle = () => {
        setIsOpen(!isOpen)
    }
    const fetchInfoSection = async () => {
        try {
            const images = await getInfoImages()
            const data = await getInfoSectionPage()
            const imagesArray = []



            for (let i = 0; i < data.length; i++) {

                imagesArray.push ([])
                const newImages = images.filter(x => x.data['info-id'] === data[i]['data']['id']);

                newImages.forEach(image => {
                    imagesArray[i].push( {
                        original: baseURL + `CalypsoImg/` + image['data']['path'],
                        thumbnail: baseURL + `CalypsoImg/` + image['data']['path']
                    })
                })

                infoSectionData.push(
                    <InfoSection
                        key={data[i]['data']['id']}
                        alt={data[i]['data']['alt']}
                        buttonLabel={data[i]['data']['buttonLabel']}
                        description={data[i]['data']['description']}
                        headline={data[i]['data']['headline']}
                        img={imagesArray[i]}
                        imgStart={data[i]['data']['imgStart']}
                        topLine={data[i]['data']['topLine']}
                        pageName={data[i]['data']['pageName']}
                    />
                )
            }
            setLoading(false)
            setinfoSection(infoSectionData)
        } catch (e) {
            if (e) {
                console.log(e.message)
            }
        }
    }

    useEffect(() => {
        fetchInfoSection()
    }, [])

    return (
        <>

            <ScrollToTop />
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            {loading ?
                <LoadingContainer>
                    <Typical
                        steps={['Please wait...', 1000, 'We are getting your cart...', 500]}
                        loop={Infinity}
                        wrapper="p"
                    />
                </LoadingContainer>
                :
                <>
                    {infoSection}
                </>
            }
            <Footer />
        </>
    );
};

export default Home;