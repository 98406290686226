import React, { createContext, Component } from "react";

export const PaginationContext = createContext();

class PaginationProvider extends Component {
    state = {
        // countInEachPage: 0,
        // categoryId: 0,
        // categoryName: '',
        // typeId: 1,
        // pageName: ''
    }

    updatePageCounts = (countInEachPage) => {
        this.setState({
            ...this.state,
            countInEachPage: countInEachPage
        })
        
    }

    updatePageName = ({ categoryId, categoryName, typeId, pageName }) => {
        this.setState({
            ...this.state,
            categoryId: categoryId,
            categoryName: categoryName,
            typeId: typeId,
            pathName: pageName,
        })
    }

    updatePath = (pathName) => {
        this.setState({
            ...this.state,
            pathName: pathName
        })
    }

    updateZippedName = (productZippedName) => {
        this.setState({
            ...this.state,
            productZippedName: productZippedName
        })
    }

    render() {
        const contextValue = {
            paginationState: this.state,
            updatePageCounts: this.updatePageCounts,
            updatePageName: this.updatePageName,
            updatePath: this.updatePath,
            updateZippedName: this.updateZippedName
        }

        return (
            <PaginationContext.Provider value={contextValue}>
                {this.props.children}
            </PaginationContext.Provider>
        )
    }

}

export default PaginationProvider;