import React from "react";
import {Templates} from '../components/templates/index'

const BowRedirect = () => {
    return (
        <>
            <Templates />
        </>
    );
};

export default BowRedirect;