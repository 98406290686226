import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const LocationContainer =  styled.div`
    transition: 0.5s all ease-in-out;   
    /* background: var(--cream); */
    height: 20px;
    /* margin-top: -80px; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 1rem;
    position: relative;
    top: 120px;
    z-index: 1;
`;

export const LocationWrapper = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: 24px;
`;

export const LocationText = styled(Link)`
color: #000;
    display: flex;
    align-items: center;
    font-family: 'Times New Roman', Times, serif;
    padding: 0 1rem;
    cursor: pointer;
`;