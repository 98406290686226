import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';


export const TopNavContainer = styled.nav`
    transition: 0.5s all ease-in-out;   
    background: var(--cream);
    height: 20px;
    margin-top: -80px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0px;
    z-index: 11;
`;


export const TopNavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: 24px;
`;

export const TopNavIcons = styled.li`
color: var(--textGold);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    cursor: pointer;
`;


export const Nav = styled.nav`
    background: var(--darkRed);
    height: 80px;
    margin-top: -60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0px;
    z-index: 10;
    
    @media screen and (max-width: 968px) {
        transition: 0.8s all ease;
    }
`;
export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 60px;
    z-index: 1;
    width: 100%;
    margin-top: 15px;
    padding: 0 24px;
    max-width: 1500px;
    
`;


export const NavLogo = styled(LinkR)`
    color: var(--gold);
    justify-self: flex-start;
    cursor: pointer;
    /* font-size: 1.5rem; */
    display: flex;
    align-items: center;
    /* margin-left: 24px; */
    font-weight: bold;
    text-decoration: none;
    margin-top: 20px;
`;
export const ImgLogo = styled.img`
    width: 250px;
`;
export const MobileIcon = styled.div`
    display: none;
    
    @media screen and (max-width: 768px) {
        color: var(--gold);
        display: block;
        position: absolute;
        top: 10px;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }   
`;

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const NavItem = styled.li`
    height: 80px;
`;

export const NavDropDownContent = styled.div`
 background: #000;
 display: none;
  width: 150px;
  position: absolute;
  top: 80px;
  list-style: none;
  text-align: start;

`;

export const NavAContent = styled(LinkR)`
  background: #4a0606;
  cursor: pointer;
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: var(--cream);
  padding: 16px;
  font-family: harlowsi;
  color: var(--cream);
&:hover {
    background: var(--darkRed);
    color: var(--textGold)
  }

`;


export const NavLinksRoute = styled(LinkR)`
    color: var(--textGold);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-family: harlowsi;
    font-size: 1.5rem;
    &:hover ${NavDropDownContent} {
        transition: 1s all ease-in;
        display: flex;
        flex-direction: column;
        
    }
    &:hover{
        color: var(--cream);
    }
`;