import React, { useContext } from "react";
import { baseURL } from "../../contexts/GlobalContext";
import { FaRemoveFormat } from "react-icons/all";
import {
    CardViewForm,
    CardViewH2,
    CardViewIcon,
    CardViewAddToCart,
    CardViewH1,
    CardViewIconRouter,
} from "./cardViewElements";
import { PaginationContext } from "../pagination/paginationContext";

export const CardView = (cardsData) => {
    const { updatePageName } = useContext(PaginationContext);

    return (
        
        <>
            {cardsData.iscategory === "true" ?
                
                <CardViewForm iscategory={cardsData.iscategory}>
                    <CardViewH1 to={'/categoryName'} onClick={() => updatePageName({categoryId: cardsData.CardViewH1URL, categoryName: cardsData.CardViewH1Value, typeId: cardsData.cardsData.id, pageName: cardsData.pathName})}
                        iscategory={cardsData.iscategory}>{cardsData.CardViewH1Value}</CardViewH1>
                    <CardViewH2
                        iscategory={cardsData.iscategory}>{cardsData.CardViewDescription}</CardViewH2>
                    <CardViewIconRouter to={'/categoryName'} onClick={() => updatePageName({categoryId: cardsData.CardViewH1URL, categoryName: cardsData.CardViewH1Value, typeId: cardsData.cardsData.id, pageName: cardsData.pathName})}
                        iscategory={cardsData.iscategory}>
                    <CardViewIcon src={`${baseURL}CalypsoImg/${cardsData.cardViewIcon}`}
                        iscategory={"true"} />
                        </CardViewIconRouter>
                </CardViewForm>

                : cardsData.isCart === 'false' ?
                    <CardViewAddToCart
                    ><FaRemoveFormat /></CardViewAddToCart>
                    :
                    <CardViewForm iscategory={cardsData.iscategory}>

                        <CardViewIconRouter to={{
                            pathname: '/singleProduct',
                            id: cardsData.cardsData.id,
                            acc: cardsData.cardsData.accessories_id}} >
                            <CardViewIcon src={`${baseURL}CalypsoImg/${cardsData.cardViewIcon}`}
                                iscategory={"false"} />
                        </CardViewIconRouter>
                        <CardViewH2 to={{
                            pathname: '/singleProduct',
                            id: cardsData.cardsData.id,
                            acc: cardsData.cardsData.accessories_id
                        }}>{cardsData.CardViewH2Value}</CardViewH2>
                    </CardViewForm>
            }
        </>
    );
}

export default CardView;