import React, { useContext } from "react";
import {Button} from '../ButtonElements';
import ImageGallery from 'react-image-gallery'
import {
    InfoContainer,
    BtnWrap,
    Column1,
    Column2,
    Heading,
    ImgWrap,
    InfoRow,
    InfoWrapper,
    Subtitle,
    TextWrapper,
    TopLine,
    TopLineLink
} from "./infoElements";
import '../singleProduct/image-gallery.css'
import { PaginationContext } from "../pagination/paginationContext";

const InfoSection = ({
                         id,
                         imgStart,
                         topLine,
                         headline,
                         description,
                         buttonLabel,
                         img,
                         pageName
                     }) => {


     const { updatePath } = useContext(PaginationContext);
    return (
        <>
            <InfoContainer id={id}>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine><TopLineLink to={pageName} onClick={() => updatePath(pageName)}>{topLine}</TopLineLink></TopLine>
                                <Heading>{headline}</Heading>
                                <Subtitle>{description}</Subtitle>
                                <BtnWrap>
                                    <Button
                                        to={pageName}
                                        smooth={"true"}
                                        duration={500}
                                        spy={"true"}
                                        exact={"true"}
                                        offset={-80}
                                        onClick={() => updatePath(pageName)}
                                    >{buttonLabel}</Button>
                                </BtnWrap>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <ImageGallery 
                                items={img} 
                                autoPlay={true} 
                                showBullets={true}
                                showThumbnails={false}
                                showNav={false}
                                slideDuration={200}
                                useBrowserFullscreen={false}
                                />
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    );
};

export default InfoSection;