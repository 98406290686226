import styled from 'styled-components';
import {Link} from 'react-router-dom';


export const CardViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background: var(--cream); */
    padding-bottom: 50px;
    padding-top: 150px;
`;

export const CardViewWrapper = styled.div`
    max-width: 1500px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: ${({items}) => (items <= 3 ? `repeat(${items}, auto)`: '1fr 1fr 1fr 1fr')};
    grid-gap: 40px;

    @media screen and (max-width: 768px){
         grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 480px){
         grid-template-columns: 1fr;
         padding: 0 20px;
    }
`;

export const CardViewWrapper2 = styled.div`
    max-width: 1500px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: ${({items}) => (items <= 3 ? `repeat(${items}, 1fr)`: '1fr 1fr 1fr 1fr')};
    grid-gap: 40px;

    @media screen and (max-width: 1024px){
         grid-template-columns: ${({items}) => (items <= 3 ? `repeat(${items}, 1fr)`: '1fr 1fr 1fr')}
    }

    @media screen and (max0width: 600px) {
        grid-template-columns: ${({items}) => (items <= 2 ? `repeat(${items}, 1fr)`: '1fr 1fr')}
    }

    @media screen and (max-width: 480px){
         grid-template-columns: 1fr;
         padding: 0 20px;
    }
`;

// ${({items}) => (items < 3 ? `repeat(${items}, 1fr)`: '1fr 1fr 1fr 1fr')}
export const CardViewIcon = styled.img`
    height: ${({iscategory}) => (iscategory === "true" ? ' 80%' : '85%')};
    /* object-fit: ${({iscategory}) => (iscategory === "true" ? ' initial' : 'cover')}; */
    object-fit: contain;
    width: 100%;
    
    margin-bottom: ${({iscategory}) => (iscategory === "true" ? 's' : '10px')};
    border-top-left-radius: ${({iscategory}) => (iscategory === "true" ? ' 50px' : '10px')};
    border-top-right-radius: ${({iscategory}) => (iscategory === "true" ? ' 50px' : '10px')};
    position: ${({iscategory}) => (iscategory === "true" ? ' absolute' : '')};
    bottom: ${({iscategory}) => (iscategory === "true" ? ' 0' : '')};
    right:${({iscategory}) => (iscategory === "true" ? ' 0' : '')};
    padding: ${({iscategory}) => (iscategory === "true" ? ' 15px' : '20px')};
    /* border-bottom-left-radius: ${({iscategory}) => (iscategory === "true" ? '' : '50px')};
    border-bottom-right-radius: ${({iscategory}) => (iscategory === "true" ? '' : '50px')}; */
`;
export const CardViewIconRouter = styled(Link)`
    /* height: ${({iscategory}) => (iscategory === "true" ? ' 60%' : '50%')};
    width: 100%;
    margin-bottom: ${({iscategory}) => (iscategory === "true" ? '0' : '10px')};
    border-top-left-radius: ${({iscategory}) => (iscategory === "true" ? ' 50px' : '10px')};
    border-top-right-radius: ${({iscategory}) => (iscategory === "true" ? ' 50px' : '10px')};
    position: ${({iscategory}) => (iscategory === "true" ? 'absolute' : '')};
    bottom: ${({iscategory}) => (iscategory === "true" ? ' 0' : '')};
    right:${({iscategory}) => (iscategory === "true" ? ' 0' : '')}; */
`;
export const CardViewForm = styled.form`
    position: relative;
    background: var(--darkRed);
    flex-direction: column;
    border-radius: 10px;
    max-height: ${({iscategory}) => (iscategory === "true" ? '500px' : '420px')};
    height: ${({iscategory}) => (iscategory === "true" ? '500px' : '400px')};
    text-align: ${({iscategory}) => (iscategory === "true" ? 'center' : 'left')}; 
    transition: all 0.2s ease-in-out;
    padding: ${({iscategory}) => (iscategory === "true" ? '50px' : '0')};
    display: ${({iscategory}) => (iscategory === "true" ? ' flex' : '')};
    width: 250px;
    &:hover {
        transform: scale(1.01);
        transition: all 0.2s ease-in-out;
        box-shadow: 0 0 35px -20px var(--darkRed);
    }
    
    &:hover ${CardViewIcon}{
        transition: all 0.2s ease-in-out;
        box-shadow: 0 0 35px -20px var(--darkRed);
    }
    
`;

export const CardViewTopH1 = styled(Link)`
    font-size: 2.5rem;
    color: var(--textGold);
    text-decoration: none;
    display: block;
    text-align: center;
    font-family: magneto;
    &:hover {
      text-decoration: ${({iscategory}) => (iscategory === "true" ? 'underline' : 'none')};
      cursor: ${({iscategory}) => (iscategory === "true" ? 'pointer' : 'auto')};
    }
    
    @media screen and (max-width: 480px){
     font-size: 2rem;
    }
`;
export const CardViewTopH2 = styled.h2`
    font-size: 1.5rem;
    color: var(--textGold);
    text-decoration: none;
    text-align: right;
    font-family: freestylets;
    margin-bottom: ${({iscategory}) => (iscategory === "true" ? '20px' : '50px')};
    @media screen and (max-width: 480px){
     font-size: 2rem;
    }
`;

export const CardViewH1 = styled(Link)`
    font-size: 2.5rem;
    color: var(--textGold);
    text-decoration: none;
    display: block;
    text-align: center;
    margin-bottom: ${({iscategory}) => (iscategory === "true" ? '20px' : '50px')};
    font-family: harlowsi;
    &:hover {
      text-decoration: ${({iscategory}) => (iscategory === "true" ? 'underline' : 'none')};
      cursor: ${({iscategory}) => (iscategory === "true" ? 'pointer' : 'auto')};
    }
    
    @media screen and (max-width: 480px){
     font-size: 2rem;
    }
`;


export const CardViewH2 = styled(Link)`
    font-size: 1rem;
    /* margin-left: 10px; */
    text-decoration: none;
    color: var(--gold);
    display: block;
    cursor: pointer;
    font-weight: bold;
    padding: ${({iscategory}) => (iscategory === "true" ? '0' : '0 5px')};
    text-align: center;

`;

export const CardViewDescription = styled.p`
    font-size: 1.1rem;
    text-align: left;
    color: var(--textGold);
    padding: 10px;
`;
export const CardViewButton = styled(Link)`
    background: var(--gold);
    color: ${({dark}) => (dark ? '#000' : '#fff')};
    font-size: 1rem;
    text-align: center;
    padding: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    text-decoration: none;
    width: 80%;
    height: 35px;
    float: right;
    margin-right: 23px;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: var(--gold);
    }
`;
export const CardViewAddToCart = styled.button`
    background: var(--darkRed);
    color: ${({dark}) => (dark ? '#000' : '#fff')};
    font-size: 0.8rem;
    text-align: center;
    padding: 10px;
    text-decoration: none;
    width: 40px;
    height: 35px;
    float: right;
    margin-right: 10px;
    border: none;
    cursor: pointer;
    outline: none;
    &:hover {
        transition: all 0.2s ease-in-out;
        background: var(--darkRed);
    }
`;

export const Text = styled.span`
    text-align: center;
    margin-top: 24px;
    color: #fff;
    font-size: 14px;
`;