import React from 'react';
import './App.css';
import {HashRouter as Router, Switch, Route} from 'react-router-dom';
import Home from "./pages";
import PianoRedirect from "./pages/piano";
import GuitarRedirect from "./pages/guitar";
import BowRedirect from "./pages/bsi"
import GlobalProvider from './contexts/GlobalContext';
import {CategoryName} from "./components/templates/category/categoryName";
import PaginationProvider from "./components/pagination/paginationContext";
import SingleProduct from "./components/singleProduct";
function App() {
  return (
      <GlobalProvider>
        <PaginationProvider>
          <Router>
            <Switch>
              <Route path={"/"} component={Home} exact/>
              <Route path={"/guitars"} component={GuitarRedirect} exact/>
              <Route path={"/pianos"} component={PianoRedirect} exact/>
              <Route path={"/bowStringed"} component={BowRedirect} exact/>
              <Route path={"/categoryName"} component={CategoryName} exact/>
                <Route path={"/singleProduct"} component={SingleProduct} exact/>
            </Switch>
          </Router>
        </PaginationProvider>
      </GlobalProvider>
  );
}

export default App;
