/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../../components/sidebar";
import Navbar from "../../../components/navbar/";
import Footer from "../../../components/footer";
import ScrollToTop from "../../../components/ScrollToTop";
import CardView from "../../../components/cardView";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { PaginationContext } from "../../pagination/paginationContext";
import { CardViewContainer, CardViewTopH1, CardViewWrapper2 } from "../../cardView/cardViewElements";
import Typical from "react-typical";
import { LoadingContainer } from "../index";
import { LocationContainer, LocationText, LocationWrapper } from "../../location/locationElements";


export const CategoryName = () => {

    const { getAllTemplatesData } = useContext(GlobalContext);
    const { paginationState } = useContext(PaginationContext);
    let { categoryId, categoryName, typeId } = paginationState;
    let templatesData = []
    const [templates, setTemplates] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [items, setItems] = useState(0);
    const toggle = () => {
        setIsOpen(!isOpen)
    }
    const [loading, setLoading] = useState(true);
    const fetchTemplates = async () => {
        try {

            if (categoryId === 0) {
                categoryId = 1
            }
            const data = await getAllTemplatesData(categoryId, categoryName, typeId)

            if (data['success'] !== 0) {
                setItems(data.length);
                for (let i = 0; i < data.length; i++) {
                    templatesData.push(
                        <CardView key={data[i]['data']['id']}
                            cardsData={data[i]['data']}
                            cardDescription={data[i]['data']['description']}
                            cardViewIcon={data[i]['data']['path']}
                            CardViewH2Value={data[i]['data']['name']}
                            iscategory={"false"}
                            items={items} />
                    )
                }
    
                setLoading(false)
                setTemplates(templatesData)
            } else {
                setTemplates([])
            }
        } catch (e) {
            if (e) {
                console.log(e.message)
            }
        }
    }

    useEffect(() => {
        fetchTemplates()
    }, [paginationState])
    return (
        <>
            <ScrollToTop />
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <LocationContainer>
                <LocationWrapper>
                    <LocationText to="/">
                        Home
                    </LocationText>
                     &gt;&gt;
                    <LocationText to={`/${paginationState.pathName}`}>
                        {paginationState.pathName}
                    </LocationText>
                </LocationWrapper>
            </LocationContainer>
            {loading ?

                <LoadingContainer>
                    <Typical
                        steps={['Please wait...', 1000, 'We are getting your cart...', 500]}
                        loop={Infinity}
                        wrapper="p"
                    />
                </LoadingContainer>
                :
                <>
                    <CardViewContainer id={"cardView"}>
                        <CardViewTopH1 iscategory={"false"} to={'#'} />
                        <CardViewWrapper2 iscategory={"true"} items={items}>
                            {templates}
                        </CardViewWrapper2>
                    </CardViewContainer>
                </>
            }
            <Footer />
        </>
    );
};