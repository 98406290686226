import styled from 'styled-components/macro';
import { Link as LinkR } from 'react-router-dom';



export const InfoContainer = styled.div`
    color: #fff;
    @media screen and (max-width: 768px) {
        padding: 100px 0;
    }
    position: relative;
    top: 80px;
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    max-height: 860px;
    height: 700px;
    width: 100%;
    max-width: 1500px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
    background: url('../../images/bg.png') center center;
    @media screen and (max-width: 768px) {
        height: 1200px;
    }
`;

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};
    
    @media screen and (max-width: 768px) {
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
    }
`;



export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;

`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
`;

export const TextWrapper = styled.div`
    max-width: 800px;
    padding-top: 0;
    padding-bottom: 60px;
`;

export const TopLine = styled.p`
    color: var(--darkRed);
    font-size: 32px;
    line-height: 16px;
    margin-top: 50px;
    font-weight: 700;
    letter-spacing: 1.4px;
    /* text-transform: uppercase; */
    margin-bottom: 16px;
    font-family: freestylets;
`;

export const TopLineLink = styled(LinkR)`
    color: var(--darkRed);
    font-size: 32px;
    line-height: 16px;
    margin-top: 50px;
    font-weight: 700;
    letter-spacing: 1.4px;
    /* text-transform: uppercase; */
    margin-bottom: 16px;
    font-family: freestylets;
`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 700;
    color: var(--gold);
    font-family: magneto;
    @media screen and (max-width: 480px) {
    font-size: 32px;
    }
`;

export const Subtitle = styled.p`
    max-width: 500px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: var(--darkRed);
    font-family: 'Times New Roman', Times, serif;
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;
`;

export const ImgWrap = styled.div`
    max-width: 650px;
    height: 100%;
    @media screen and (max-width: 768px) {
        margin-top: -80px;
    }
`;

export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
`;