/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../sidebar";
import Navbar from "../navbar";
import Footer from "../footer";
import ScrollToTop from "../ScrollToTop";
import { baseURL, GlobalContext } from "../../contexts/GlobalContext";
import { useLocation } from "react-router-dom";
import ImageGallery from 'react-image-gallery'
import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    Column2,
    TextWrapper,
    Subtitle,
    ImgWrap,
    Heading,

} from "./productElements"
import "./image-gallery.css"
import { LocationContainer, LocationText, LocationWrapper } from "../location/locationElements";
import { PaginationContext } from "../pagination/paginationContext";
const SingleProduct = () => {
    const { getSingleProduct } = useContext(GlobalContext);
    const { paginationState, updatePageName } = useContext(PaginationContext)
    const location = useLocation()

    const [isOpen, setIsOpen] = useState(false);
    const [infoSection, setinfoSection] = useState([])

    let infoSectionData = []
    let images = [];
    const toggle = () => {
        setIsOpen(!isOpen)
    }
    const fetchInfoSection = async () => {
        try {
            const data = await getSingleProduct(location.id, location.acc)
            let i = 0;
            for (let z = 0; z < data.length; z++) {
                images.push({
                    original: baseURL + `CalypsoImg/` + data[z]['data']['path'],
                    thumbnail: baseURL + `CalypsoImg/` + data[z]['data']['path']
                });
            }

            infoSectionData.push(

                <InfoContainer id={data[i]['data']['id']}>
                    <InfoWrapper>
                        <InfoRow>
                            <Column1>
                                <TextWrapper>
                                    <Heading>{data[i]['data']['name']}</Heading>
                                    <Subtitle>{data[i]['data']['description']}</Subtitle>
                                </TextWrapper>
                            </Column1>
                            <Column2>
                                <ImgWrap>
                                    
                                    <ImageGallery key={data[i]['data']['name']} items={images} useBrowserFullscreen={false} />
                                </ImgWrap>
                            </Column2>
                        </InfoRow>
                    </InfoWrapper>
                </InfoContainer>
            )
            setinfoSection(infoSectionData)
        } catch (e) {
            if (e) {
                console.log(e.message)
            }
        }
    }

    useEffect(() => {
        fetchInfoSection()
    }, [])

    return (
        <>

            <ScrollToTop />
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <LocationContainer>
                <LocationWrapper>
                    <LocationText to="/">
                        Home
                    </LocationText>
                    &gt;&gt;
                    <LocationText to={`/${paginationState.pathName}`}>
                        {paginationState.pathName}
                    </LocationText>
                    &gt;&gt;
                    <LocationText to="/categoryName" onClick={() => updatePageName({categoryId: paginationState.categoryId, categoryName: paginationState.categoryName, typeId: paginationState.typeId, pathName: paginationState.pageName})}>
                        {paginationState.categoryName}
                    </LocationText>
                </LocationWrapper>
            </LocationContainer>
            {infoSection}
            <Footer />
        </>
    );
};

export default SingleProduct;