import React, { useContext } from "react";
import {
    FaFacebook,
    FaInstagram,
    FaYoutube,
    FaMailBulk,
} from "react-icons/all";
import {
    FooterContainer,
    FooterLinkItems,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterWrap,
    FooterLinkTitle,
    FooterLink,
    SocialIconLink,

    SocialLogo,
    SocialMedia,
    SocialMediaWrap,
    FooterLogo,
} from "./footerElements";
import {animateScroll as scroll} from "react-scroll";
import logo from "../../images/LogoGold.png"
import { PaginationContext } from "../pagination/paginationContext";
const Footer = () => {
    const {updatePath} = useContext(PaginationContext)
    const toggleHome = () => {
        scroll.scrollToTop();
    }
    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Catalogue</FooterLinkTitle>
                            <FooterLink to={"pianos"} onClick={() => updatePath('pianos')}>Pianos</FooterLink>
                            <FooterLink to={"guitars"} onClick={() => updatePath('guitars')}>Guitars</FooterLink>
                            <FooterLink to={"bowStringed"} onClick={() => updatePath('bowStringed')}>Bow Stringed Instruments</FooterLink>
                            {/* <FooterLink to={"accessories"}>Accessories</FooterLink> */}
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Social Media</FooterLinkTitle>
                            <SocialIconLink href={"https://www.facebook.com/Calypso-Music-Instruments-109297437441222/"} target='_blank' aria-label='Facebook' rel="noopener" primary><FaFacebook /> Facebook</SocialIconLink>
                            <SocialIconLink href={"https://www.instagram.com/calypsomusicinstrumemts/"} target='_blank' aria-label='Instagram'><FaInstagram /> Instagram</SocialIconLink>
                            <SocialIconLink href={"https://www.facebook.com/Calypso-Music-Instruments-109297437441222/"} target='_blank' aria-label='YouTube'><FaYoutube /> YouTube</SocialIconLink>
                            <SocialIconLink href={"mailto:calypsomusicinstruments@gmail.com"} target='_blank' aria-label='Email'><FaMailBulk /> Email</SocialIconLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to={"/"} onClick={toggleHome}>
                            <FooterLogo src={logo} />
                        </SocialLogo>
                            
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    );
};

export default Footer;